import RetrieveCustomerInfo from '../../../mocks/aws/authorization-retrieveCustomerInfo.json';
import RetrieveItems from '../../../mocks/aws/items-retrieveItems.json';
import retrieveCustomerProducts from '../../../mocks/aws/catalog-retrieveCustomerProducts.json';
import RetrieveMOP from '../../../mocks/aws/mop-retrieveMOP.json';
import RetrieveNexiParam from '../../../mocks/aws/mop-retrieveNexiParam.json';
import AssociateNexiPayment from '../../../mocks/aws/mop-associateNexiPayment.json';
import RetrieveActivableNBA from '../../../mocks/aws/catalog-retrieveActivableProducts-nba.json';
import RetrieveActivableADDONMM from '../../../mocks/aws/catalog-retrieveActivableProducts-addonmm.json';
import ActivateProductsByPush from '../../../mocks/aws/catalog-activateProductsByPush.json';
import ActivateProductsSkipCheck from '../../../mocks/aws/catalog-activateProducts-skipcheck.json';
import ActivateProductsNoSkipCheck from '../../../mocks/aws/catalog-activateProducts-noskipcheck.json';
import RetrieveFlexPageUrl from '../../../mocks/aws/externalservices-retrieveFlexPageUrl.json';
import familyRetrieveFamilyInfo from '../../../mocks/aws/family-retrieveFamilyInfo.json';
import familyRetrieveFamilyCosts from '../../../mocks/aws/family-retrieveFamilyCosts.json';
import familyRetrieveFamilyBenefits from '../../../mocks/aws/family-retrieveFamilyBenefits.json';
import familyRetrieveMemberInfo from '../../../mocks/aws/family-retrieveMemberInfo.json';
import RetrieveCustomerInvoices from '../../../mocks/aws/invoices-retrieveCustomerInvoices.json';
import RetrieveCustomerInvoice from '../../../mocks/aws/invoices-retrieveCustomerInvoice.json';
import RetrieveCustomerInvoiceDocument from '../../../mocks/aws/invoices-retrieveCustomerInvoiceDocument.json';
import RetrieveBillingInfo from '../../../mocks/aws/invoices-retrieveBillingInfo.json';
import UpdateDeliveryMode from '../../../mocks/aws/invoices-updateDeliveryMode.json';
import ChangeBillingDeliveryMailAddress from '../../../mocks/aws/invoices-changeBillingDeliveryMailAddress.json';
import CheckPaymentOrder from '../../../mocks/aws/mop-checkPaymentOrder.json';
import AnchorResolver from '../../../mocks/aws/anchorresolver.json';
import AnchorResolverToken from '../../../mocks/aws/anchorresolver-token.json';
import R1cNexiPayment from '../../../mocks/aws/mop-r1cNexiPayment.json';
import OmniTrackingRetrieveItemTroubleTickets from '../../../mocks/aws/omnitracking-retrieveItemTroubleTickets.json';
import OmniTrackingRetrieveGenericTroubleTickets from '../../../mocks/aws/omnitracking-retrieveGenericTroubleTickets.json';

export const mockAWSApi = {
  // Authorization
  '/authorization/v1/retrieveCustomerInfo': RetrieveCustomerInfo,
  // Items
  '/items/v1/retrieveItems\\?contactId=\\w+&retrieveRecurringChargePeriod=false&retrieveProductCounters=false&+(.*)': RetrieveItems,
  // MOP
  '/mop/v1/retrieveMOP\\?contactId=(.*)': RetrieveMOP,
  '/mop/v1/retrieveNexiParam': RetrieveNexiParam,
  '/mop/v1/associateNexiPayment': AssociateNexiPayment,
  '/mop/v1/checkPaymentOrder': CheckPaymentOrder,
  '/mop/v1/r1cNexiPayment': R1cNexiPayment,
  // CATALOG
  '/catalog/v1/retrieveCustomerProducts\\?customerId=(.*)': retrieveCustomerProducts,
  '/catalog/v1/retrieveActivableProducts\\?productType=NBA&sims=\\w+.*': RetrieveActivableNBA,
  '/catalog/v1/retrieveActivableProducts\\?productType=ADDON&productType=MASSMARKET&sims=\\w+.*': RetrieveActivableADDONMM,
  '/catalog/v1/activateProductsByPush\\?sim=\\w+.*': ActivateProductsByPush,
  '/catalog/v1/activateProducts\\?skipCheck=false&sim=\\w+.*': ActivateProductsSkipCheck,
  '/catalog/v1/activateProducts\\?skipCheck=true&sim=\\w+.*': ActivateProductsNoSkipCheck,
  // Externalservices
  '/externalservices/v1/compass/retrieveFlexPageUrl': RetrieveFlexPageUrl,
  // Family
  '/family/v1/retrieveFamilyInfo\\?contactId=\\w+&customerId=\\w+&noLoader=true': familyRetrieveFamilyInfo,
  '/family/v1/retrieveFamilyCostInfo\\?contactId=\\w+&customerId=\\w+&noLoader=true': familyRetrieveFamilyCosts,
  '/family/v1/retrieveItemBenefitInfo\\?contactId=\\w+&item=\\w+&noLoader=true': familyRetrieveFamilyBenefits,
  '/family/v1/retrieveMemberInfo\\?customerId=\\w+&item=\\w+&contactId=\\w+&noLoader=true': familyRetrieveMemberInfo,
  // Invoices
  '/invoices/v1/retrieveCustomerInvoices': RetrieveCustomerInvoices,
  '/invoices/v1/retrieveCustomerInvoiceDocument': RetrieveCustomerInvoiceDocument,
  '/invoices/v1/retrieveCustomerInvoice': RetrieveCustomerInvoice,
  '/invoices/v1/retrieveBillingInfo': RetrieveBillingInfo,
  '/invoices/v1/updateDeliveryMode': UpdateDeliveryMode,
  '/invoices/v1/changeBillingDeliveryMailAddress': ChangeBillingDeliveryMailAddress,
  // Anchor Resolver
  '/anchorresolver/v1/lookup\\?anchorId=(NA_RICARICA_ANONYMOUS_NO_OV|direct_na_ricaricaanonymousnoov)&username=\\w+&customerId=\\w+&contactId=\\w+&fiscalCode=\\w+&status=\\w': AnchorResolver,
  '/anchorresolver/v1/lookup\\?anchorId=(NA_RICARICA_WITH_TOKEN_NO_OV|direct_na_ricaricawithtokennoov|NA_RICARICA_WITH_TOKEN_NO_OV_MOP)&username=\\w+&customerId=\\w+&contactId=\\w+&fiscalCode=\\w+&status=\\w+&msisdn=\\w': AnchorResolverToken,
  // Omnitracking
  '/troubleticket/v1/retrieveItemTroubleTickets': OmniTrackingRetrieveItemTroubleTickets,
  '/troubleticket/v1/retrieveGenericTroubleTickets': OmniTrackingRetrieveGenericTroubleTickets
};

